<template>
  <div class="page-wrapper contactus">
    <div class="jumbotron">
      <div class="container">
        <h2 class="mb-4 heading" data-aos="fade-down">Contact</h2>
      </div>
    </div>
    <section class="contact spad">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6" data-aos="fade-right">
            <div class="contactcontent">
              <div class="contactform">
                <h5>Buisness Enquiry</h5>
                <form action="#" @submit.prevent="submitForm">
                  <div class="col-md-12">
                    <label>Name</label><span style="color: red"> *</span>
                    <input
                      name="name"
                      type="text"
                      class="form-control"
                      v-model.trim="$v.message.name.$model"
                      :class="{
                        'is-invalid':
                          !$v.message.name.required && $v.message.name.$dirty
                      }"
                    />
                    <div
                      class="text-danger"
                      v-if="!$v.message.name.required && $v.message.name.$dirty"
                    >
                      Name is required
                    </div>
                  </div>
                  <div class="col-md-12">
                    <label>Phone</label><span style="color: red"> *</span>

                    <input
                      type="phone"
                      maxlength="10"
                      class="form-control"
                      v-model.trim="$v.message.phone.$model"
                      :class="{
                        'is-invalid':
                          !$v.message.phone.required && $v.message.phone.$dirty
                      }"
                    />
                    <div
                      class="text-danger"
                      v-if="
                        !$v.message.phone.required && $v.message.phone.$dirty
                      "
                    >
                      Phone is required
                    </div>
                  </div>
                  <div class="col-md-12">
                    <label>Email</label><span style="color: red"> *</span>

                    <input
                      type="mail"
                      class="form-control"
                      v-model.trim="$v.message.email.$model"
                      :class="{
                        'is-invalid':
                          !$v.message.email.required && $v.message.email.$dirty
                      }"
                    />
                    <div
                      class="text-danger"
                      v-if="
                        !$v.message.email.required && $v.message.email.$dirty
                      "
                    >
                      Email is required
                    </div>
                  </div>
                  <div class="col-md-12">
                    <label>Message</label><span style="color: red"> *</span>

                    <textarea
                      class="form-control"
                      v-model.trim="$v.message.message.$model"
                      :class="{
                        'is-invalid':
                          !$v.message.message.required &&
                          $v.message.message.$dirty
                      }"
                    ></textarea>
                    <div
                      class="text-danger"
                      v-if="
                        !$v.message.message.required &&
                          $v.message.message.$dirty
                      "
                    >
                      Message is required
                    </div>
                  </div>

                  <div class="col-md-12 filein">
                    <input
                      type="file"
                      name="imageUpload"
                      accept="application/pdf, application/msword, application/iges, application/msexcel, application/acad"
                      id="imageUpload"
                      class="hiden"
                      @change="uploadFile"
                    />
                    <label for="imageUpload" class="btn">Upload</label>
                    <span
                      v-if="filename != null"
                      style="
                        font-size: 15px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                    >
                      {{ filename }}
                      <span class="delete" @click="deleteFile"> x </span>
                    </span>
                  </div>
                  <div class="col-md-12">
                    <v-btn
                      class="ma-2 submitbtn"
                      type="submit"
                      :loading="loading"
                      :disabled="loading"
                    >
                      Send
                    </v-btn>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6" data-aos="fade-left">
            <div class="contactaddress">
              <h5>Unit 1</h5>
              <ul>
                <li>
                  <h6>
                    <i class="bx bx-map" style="color: #010181"></i> Address
                  </h6>
                  <p>
                    45, Nehru St, TMP Nagar, Padi, Chennai, Tamil Nadu 600050
                  </p>
                </li>
                <h5>Unit 2</h5>
                <li>
                  <h6>
                    <i class="bx bx-map" style="color: #010181"></i> Address
                  </h6>
                  <p>
                    26, Avvai St, TMP Nagar, Padi, Chennai, Tamil Nadu 600050
                  </p>
                </li>
              </ul>
            </div>
            <br />
            <div class="contactaddress">
              <ul>
                <li>
                  <h6>
                    <i class="bx bx-phone" style="color: #010181"></i> Phone
                  </h6>
                  <span class="phn">044 26241435</span>
                  <span class="phn">+91 8610190094</span>
                  <span class="phn">+91 9176665601</span>
                </li>
                <li>
                  <h6>
                    <i class="bx bx-envelope" style="color: #010181"></i>
                    Support
                  </h6>
                  <p>
                    <a href="mailto:manohar@manotools.com"
                      >manohar@manotools.com - Official</a
                    >
                  </p>
                  <p>
                    <a href="mailto:sales@manotools.com"
                      >sales@manotools.com - Sales Queries</a
                    >
                  </p>
                  <p>
                    <a href="mailto:design@manotools.com"
                      >design@manotools.com - Design Queries</a
                    >
                  </p>
                </li>
                <li></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 col-md-6"></div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="contact__map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15544.217845689514!2d80.178851!3d13.095735!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4433c67b49adfff4!2sMano%20Tools!5e0!3m2!1sen!2sin!4v1612273438756!5m2!1sen!2sin"
                width="400"
                height="300"
                frameborder="0"
                style="border: 0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="contact__map">
              <!-- <h1>Plant-2</h1> -->
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.062637527534!2d80.17830961464662!3d13.095216790774861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52633902d6a451%3A0x1be4f95e582dc1a0!2sMano%20Tools%20Plant%20II!5e0!3m2!1sen!2sin!4v1615618080167!5m2!1sen!2sin"
                width="400"
                height="300"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Contact Section End -->
  </div>
</template>

<script>
import { fb } from "../firebase.js";
//eslint-disable-next-line
import axios from "axios";
import { required, email, maxLength } from "vuelidate/lib/validators";

export default {
  name: "Contactus",
  components: {},
  data() {
    return {
      filename: null,
      sending: false,
      loading: false,
      show: false,
      message: {
        name: null,
        email: null,
        phone: null,
        message: null,
        file: null
      }
    };
  },
  validations: {
    message: {
      name: {
        required
      },
      email: {
        required,
        email
      },
      phone: {
        required,
        maxLength: maxLength(10)
      },
      message: {
        required
      }
    }
  },
  methods: {
    uploadFile(e) {
      if (e.target.files[0]) {
        let file = e.target.files[0];
        const size = Math.round(file.size / 1024);
        if (size > 2000) {
          alert("file size is greter than 2mb");
        } else {
          this.filename = file.name;
          var storageRef = fb.storage().ref("contactFiles/" + file.name);

          let uploadTask = storageRef.put(file);

          uploadTask.on(
            "state_changed",
            //eslint-disable-next-line
            (snapshot) => {
              // console.log(snapshot);
            },
            error => {
              console.log(error);
            },
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...

              uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                this.message.file = downloadURL;
              });
            }
          );
        }
      }
    },
    deleteFile() {
      let image = fb.storage().refFromURL(this.message.file);
      image
        .delete()
        .then(function() {
          // console.log("file deleted");
        })
        .catch(function(err) {
          console.log(err);
          console.log("err ocurred");
        });
      this.filename = null;
    },
    reset() {
      this.message.name = null;
      this.message.email = null;
      this.message.phone = null;
      this.message.message = null;
      this.message.file = null;
    },
    submitForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.loading = true;
        var date = new Date();
        date.toISOString();
        axios
          .post(
            "https://asia-south1-mano-tools.cloudfunctions.net/mail/contactus",
            {
              email: this.message.email,
              name: this.message.name,
              phone: this.message.phone,
              message: this.message.message,
              file: this.message.file,
              date: date
            }
          )
          .then(res => {
            if (res.data == "Mail sent") {
              this.reset();
              this.$v.$reset();
              this.loading = false;
              this.filename = null;
              this.message.file = null;
            } else {
              alert("Failed");
            }
          });
      } else {
        console.log("Submitted InCorrectly");
      }
    }
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Contact",
    // all titles will be injected into this template
    titleTemplate: "ManoTools - %s"
  }
};
</script>

<style lang="css" scoped>
.contact {
  padding-top: 80px;
  padding-bottom: 80px;
}
a {
  color: unset !important;
}
.delete {
  background: red;
  color: white;
  border-radius: 25%;
  padding: 2px 6px;
  cursor: pointer;
  margin-left: 25px;
}
a:hover {
  text-decoration: none !important;
}
.contactcontent {
  margin-bottom: 45px;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #6666f1 !important;
}
.v-progress-circular {
  color: white !important;
}
.submitbtn {
  font-size: 13px !important;
  margin-top: 30px !important;
  color: #ffffff !important;
  background: #010181 !important;
  font-weight: 600 !important;
  border: none !important;
  text-transform: uppercase !important;
  display: inline-block !important;
  padding: 12px 30px !important;
  border-radius: 50px !important;
}

.hiden {
  display: none;
}

.btn {
  display: inline-block;
  padding: 4px 12px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 10px #eee;
  border-radius: 4px;
  padding: 11px 19px;
  font-size: 17.5px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.contactaddress h5 {
  color: #111111;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.contactaddress ul li {
  list-style: none;
  position: relative;
  margin-bottom: 20px;
}

.contactaddress ul li:last-child {
  margin-bottom: 0;
}

.contactaddress ul li h6 {
  color: #111111;
  font-weight: 600;
  margin-bottom: 10px;
}

.contactaddress ul li p {
  margin-bottom: 0;
  font-size: 15px;
  color: #444444;
}

.contactaddress ul li span {
  font-size: 15px;
  color: #444444;
  display: inline-block;
  margin-right: 25px;
  position: relative;
}

.contactaddress ul li span:after {
  position: absolute;
  right: -15px;
  top: 11px;
  content: "|";
  line-height: 0;
}
@media screen and (max-width: 400px) {
  .contactaddress ul li span:after {
    display: none;
  }
  .phn {
    display: block !important;
  }
}
.contactaddress ul li span:last-child {
  margin-right: 0;
}

.contactaddress ul li span:last-child:after {
  display: none;
}

.contactform h5 {
  color: #111111;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 35px;
}

.contactform form input {
  height: 50px;
  width: 100%;
  padding-left: 20px;
  font-size: 14px;
  color: #444444;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
}
label {
  margin-top: 20px;
}
.contactform form textarea {
  height: 130px;
  width: 100%;
  padding-left: 20px;
  padding-top: 12px;
  font-size: 14px;
  color: #444444;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  resize: none;
}
.contact__map {
  height: auto;
}

.contact__map iframe {
  width: 100%;
}
.jumbotron {
  background: linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.4),
        rgba(5, 6, 116, 0.6)
      )
      fixed,
    url("../assets/img/contact_us.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-radius: 0px !important;
}
.heading {
  /* padding-left: 12%; */
  text-align: center;
  font-weight: 700;
  color: #ffffff;
}
</style>
